/* The following line can be included in a src/App.scss */

@import "~bootstrap/scss/bootstrap";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-container {
  height: 100vh;

  .full-div {
    height: 100vh;
  }
}

.main-mobile-img {
    height: 100%;
    max-height: 3000px;
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
}

.locLogo{
  height: 100px;
  width: 280px;
  // -moz-animation: spinHorizontal 5s infinite  3s linear;
  // -o-animation: spinHorizontal 5s infinite 3s linear;    
  // -webkit-animation: spinHorizontal 5s infinite 3s linear;
  // animation: spinHorizontal 5s infinite  3s linear;
  // animation-delay: 10s;
  animation: glow 1s linear forwards;
  -webkit-animation: glow 1s linear forwards;
  -moz-animation: glow 1s linear forwards;
  -o-animation: glow 1s linear forwards;
  -ms-animation: glow 1s linear forwards;
  position: absolute;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  // animation: glow 15s ease;
  position: fixed;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

.App-link {
  color: #61dafb;
}

.fade-in {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
  }
  @keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }