.podcast-container {
    position: relative;
    padding: 10px;
    background: whitesmoke;

    .transparent-background {
        
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .content-container {
        border-top: 3px solid brown;
        border-bottom: 3px solid brown;
        padding: 30px 10px;
    }

    .podcast-txt {
        font-size: 0.6rem
    }

    .podcast-container {

        .iframe {
            margin: 20px 0px;
            min-height: 220px;
        }
    }
}