.base-background {
    padding: 10px;
    position: relative;
    background: whitesmoke;

    .about-container {
        border-top: 3px solid brown;
        border-bottom: 3px solid brown;
        padding: 30px 10px;
    }

    .hide {
        display: none
    }
}