
nav {
    // background-color: darkblue;
    height: 80px;
    width: 100%;
    z-index: 10;

    div {
        color: white;
    }

    .navbar-collapse {
        background: #343a40;
    }

    
}

.homeIcon{
    font-size: 50px;
    color: white;
}

.buttonHome {
    background-color: slategray;
    border-radius: 50px;
    position: absolute;
    right: 30px;
    z-index: 100;
    border: none;
    bottom: 50px;
    padding: 10px;
    box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);
    cursor: pointer;
}

.navBar {
    // position: fixed;
    z-index: 100;
    width:100%;
    padding: 0;

    .tag-line {
        font-size: 0.6rem;
    }
}