.youtube-container {
    padding: 10px;
    position: relative;
    background: whitesmoke;

    .content-container {
        border-top: 3px solid brown;
        border-bottom: 3px solid brown;
        padding: 30px 10px;
    }

    .play-btn {
        margin: 10px 0px;
        border-radius: 10px;
        animation: glow 1.5s ease-in-out infinite alternate;
    }

    @keyframes glow {
        from {
          box-shadow: 0 0 5px -5px lightslategray;
        }
        to {
          box-shadow: 0 0 5px 5px lightslategray;
        }
      }

    .title-txt {
        height: 70px;
        margin: 50px 0px;
        max-width: 320px;
      
        &.youtube {
          margin: 50px 0px 20px;
        }
      }

    .youtube-space {
        margin: 10px 0px;
    }
    
    .youtubeButton {
        background: red;
        border-color: red;
        color: white;
        position: relative;
    
        //CSS triangle
        // &:after {
        //     content: '';
        //     position: absolute;
        //     left: 18px;
        //     top: 50%;
        //     width: 0;
        //     left: 50%;
        //     -webkit-transform: translateX(-50%);
        //     transform: translateX(-50%);
        //     border-left: 20px solid transparent;
        //     border-right: 20px solid transparent;
        //     border-top: 20px solid white;
        //     // clear: both;
        // }
    }

    .iframe {
        width: 100%;
        min-height: 220px;
    }
}

