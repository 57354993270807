.main-container {
    height: 100%;
    position: relative;
    .section-container {
        width: 100%;
        background-color:rgba($color: black, $alpha: 0.7);
        // margin-bottom: 50%;

        .section {
            border-radius: 5px;
            padding: 50px;
            font-size: 1.8rem;
            font-family: Roboto, Arial, Helvetica, sans-serif;
            font-weight: 1200;
            background-color: #333333;
            color: white;
            margin: 4px 0px;
            border: 1px solid white;
        }

        .section:hover {
            background-color: lightslategray;
        }
    }
    .fade-enter {
        opacity: 0;
   }
    
   .fade-enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
    }
    
    // .fade-exit {
    //     opacity: 1;
    // }
    
    // .fade-exit.fade-exit-active {
    //     opacity: 0;
    //     transition: opacity 300ms ease-in;
    // }
}