.footer {
    height: 80px;
    width: 100%;
    background-color: #343a40;
    // margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;

   color: white;

    .socialIcons  {
        margin: 0px 8px;
        height: 40px;
        width: 40px;
    }

    .like {
        margin-right: 10px;
        font-size: 0.8rem;
    }
}