.contact-container {
    padding: 10px;
    position: relative;
    // background: rgba(255, 250, 250, 0.15);
    background: whitesmoke;
    min-height: 100vh;
    height: 100vh;

    .border-container {
        border-top: 3px solid brown;
        border-bottom: 3px solid brown;
        padding: 30px 10px;
        height: 100%
    }

    .emailAddress {
        margin-left: 10px;
    }

    .socialLinkContainer {
        margin-top: 30px;
    }
    .socialLinks { 
        margin: 10px;
    }
}

