.mission-container {
    height: 100%;
    // max-height: 1000px;
    position: relative;
    background-image: url('../../assets//images/mission-bg.jpeg');
    width: 100%;
    background-size: 100% 100%;
    
    div {
        color: white;
    }

    .mission-title {
        padding: 10px;
        height: 100%;
        z-index: 10;
        top: 80%;  /* position the top  edge of the element at the middle of the parent */
        left: 50%; /* position the left edge of the element at the middle of the parent */
        // transform: translate(-50%, -50%);
        // position: absolute;
        width: 100%;

        .mission-txt {
            padding: 10px;
            top: 200px;  /* position the top  edge of the element at the middle of the parent */
            left: 50%; /* position the left edge of the element at the middle of the parent */
            // transform: translate(-50%, -50%);
            // position: absolute;
            width: 100%;
            display: flex;
            justify-content: start;
            background-color: rgba(0,0,0,0.8);

            p {
                text-align: left;
            }
        }
    }


    .mission-img {
        width: 100%;
        height: 100%;
    }
}