// color varialbes
// $background-color: #333;

$background-color: #333;

$navbar-background: #343a40;



body, html {
  height: 100%;
  width:100%;
  margin: 0;

  // background-color
  background-color: $background-color !important;
  font-size: 14px;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // color of background
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  color: #333;
}

// Global styles for flexbox
.flex-container {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-just-center {
  justify-content: center;
}

.flex-align-center {
  align-items: center;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

// for ai titles probably remove in future
// .title-txt {
//   height: 70px;
//   margin: 50px 0px;
//   max-width: 320px;

//   &.youtube {
//     margin: 50px 0px 20px;
//   }
// }

.posRel {
  position: relative;
}

.posFixed {
  position: fixed;
}

.posAbs {
  position: absolute;
}

.hide {
  display: none;
}

@media screen and (min-width: 1000px) {
  .title-txt {
    height: 120px;
  }
}